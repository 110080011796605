import { Menu, MenuItem, Button, Divider, ListItemText, ListItemIcon, Switch } from "@suid/material";
import { For, createSignal, createEffect, splitProps, Switch as SolidSwitch, Match } from "solid-js";
import { useNavigate } from "@solidjs/router";

import { FilterIcon,MenuIcon, AddIcon, EditIcon, DeleteIcon, SaveIcon } from '../icons';
 
import { getRatedByForCountry  } from '../DataLists/DataRatedByList'

import { FilterListMenuProps } from "../../../utils/menuUtil"


const FilterListMenu = () => {
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
    const [selectedIndex, setSelectedIndex] = createSignal<number>(1);

    const open = () => !!anchorEl();

    const handleClickListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    };

    const handleMenuItemClick = (
        event: MouseEvent,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };



    //const options = [txtAll() as string, txtManaged() as string, txtWorkedwith() as string]


    let [translatedRatedByList, setTranslatedRatedByList] = getRatedByForCountry("en");


    return (
        <>

            <Button size="small" onClick={handleClickListItem}>
                {<FilterIcon />}
                {(translatedRatedByList() as { code: string; itemType: string; label: string; }[]).length > selectedIndex() && (translatedRatedByList() as { code: string; itemType: string; label: string; }[])[selectedIndex()].label}
            </Button>
            <Menu
                id="lock-menu" anchorEl={anchorEl()} open={open()} onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {
                    <For each={translatedRatedByList()}>{(item, index) =>
                        <SolidSwitch >
                            <Match when={item?.itemType === "action"}>
                                <MenuItem
                                    disabled={index() === 0}
                                    selected={index() === selectedIndex()}
                                    onClick={(event) => handleMenuItemClick(event, index())}
                                    value={item?.code}
                                >
                                    {item?.label}
                                </MenuItem>
                            </Match>



                        </SolidSwitch>
                    }
                    </For>
                }
            </Menu>
        </>
    );
}


const PageListMenu = (props: FilterListMenuProps) => {
    const [local] = splitProps(props, ['children'])
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
    const [selectedIndex, setSelectedIndex] = createSignal<number>(1);

    const open = () => !!anchorEl();
    const navigate = useNavigate();

    const handleClickListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    };

    const handleMenuItemClick = (
        event: MouseEvent,
        index: number,
    ) => {
        setAnchorEl(null);
      //  props.handleEvent && props.handleEvent();
        props.options[index]?.handleMenuClick && props.options[index]?.handleMenuClick?.();
    };


    const handleClickAction = (index: number) => {
        setAnchorEl(null);
       // props.handleEvent && props.handleEvent();
        props.options[index]?.handleMenuClick && props.options[index]?.handleMenuClick?.();
    };

    const handleClickRoute = (index: number) => {

        setAnchorEl(null);

        if (props.options[index]?.itemType === "action" && props.options[index]?.handleMenuClick) {
          //  props.handleEvent && props.handleEvent();
            props.options[index]?.handleMenuClick && props.options[index]?.handleMenuClick?.();
        } else {
            navigate(props.options[index]?.routeName as string);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <>

            <Button size="small" onClick={handleClickListItem}>  {<MenuIcon />}  </Button>

            <Menu
                id="lock-menu" anchorEl={anchorEl()} open={open()} onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <For each={props.options} >
                    {(option, index) =>
                        <SolidSwitch >
 
                            <Match when={option?.itemType === "route" || option?.itemType === "action"}>
                                <MenuItem


                                    onClick={(event) => (option.routeName ? handleClickRoute(index()) : handleClickAction(index()))}
                                    value={option?.value}
                                >
                                    <ListItemIcon >
                                        <SolidSwitch >
                                            <Match when={option?.icon === "select"}>
                                                <FilterIcon />
                                            </Match>
                                            <Match when={option?.icon === "add"}>
                                                <AddIcon />
                                            </Match>
                                            <Match when={option?.icon === "edit"}>
                                                <EditIcon />
                                            </Match>
                                            <Match when={option?.icon === "delete"}>
                                                <DeleteIcon />
                                            </Match>
                                            <Match when={option?.icon === "save"}>
                                                <SaveIcon />
                                            </Match>
                                        </SolidSwitch>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {option?.label}
                                    </ListItemText>
                                </MenuItem>
                            </Match>
                            <Match when={option?.itemType === "divider"}>
                                <Divider />
                            </Match>
                            <Match when={option?.itemType === "switch"}>
                                <MenuItem

                                >
                                    <ListItemIcon>
                                        <Switch id="autoconfirmedEnabled" defaultChecked />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {option?.label}
                                    </ListItemText>
                                </MenuItem>
                            </Match>
                        </SolidSwitch>
                    }
                </For>
            </Menu >
        </>
    );
}

export { FilterListMenu, PageListMenu }
