
import { createSignal, createEffect, Accessor } from "solid-js";

type CountryCode = "en" | "en-uk" | "da" | "nb" | "se" | "pl" | "fr" | "nl" | "de";


// Define a new interface for localized strings
export interface LocalizedText {
    "en": string;
    "en-uk": string;
    "da": string;
    "nb": string;    // Norwegian Bokmål
    "se": string;    // Swedish
    "pl": string;    // Polish
    "fr": string;    // French
    "nl": string;    // Dutch
    "de": string;    // German
}

// Update the RatedByType interface
export interface RatedByType {
    code: string;
    itemType: "action";
    label: LocalizedText;
}
 
const getRatedByForCountry = (countryCode: CountryCode) => {
    
        const translatedRatedByList = ratedByList.map(item => ({
            code: item.code,
            itemType: item.itemType,
            label: item.label[countryCode]
        }));
    
    return createSignal(translatedRatedByList);
}
 

const ratedByList: RatedByType[] = [
    {
        "code": "ratedby",
        "itemType": "action",
        "label": {
            "en": "Rated by",
            "en-uk": "Rated by",
            "da": "Vurderet af",
            "nb": "Vurdert av",
            "se": "Betygsatt av",
            "pl": "Oceniany przez",
            "fr": "Évalué par",
            "nl": "Beoordeeld door",
            "de": "Bewertet von"
        }
    },
    {
        "code": "all",
        "itemType": "action",
        "label": {
            "en": "All",
            "en-uk": "All",
            "da": "Alle",
            "nb": "Alle",
            "se": "Alla",
            "pl": "Wszystko",
            "fr": "Tout",
            "nl": "Alles",
            "de": "Alle"
        }
    },
    {
        "code": "manager",
        "itemType": "action",
        "label": {
            "en": "Manager",
            "en-uk": "Manager",
            "da": "Chef",
            "nb": "Leder",  // Norwegian translation
            "se": "Manager",  // Swedish translation (There is no exact Swedish word, "Chef" is also used)
            "pl": "Menedżer",  // Polish translation
            "fr": "Directeur",  // French translation
            "nl": "Manager",  // Dutch translation (the English word is commonly used)
            "de": "Manager"  // German translation (the English word is commonly used)
        }
    },
    {
        "code": "managed",
        "itemType": "action",
        "label": {
            "en": "Managed",
            "en-uk": "Managed",
            "da": "Ledet",
            "nb": "Ledet",
            "se": "Styrd",
            "pl": "Zarządzany",
            "fr": "Géré",
            "nl": "Beheerd",
            "de": "Verwaltet"
        }
    },
    {
        "code": "workwith",
        "itemType": "action",
        "label": {
            "en": "Worked with",
            "en-uk": "Worked with",
            "da": "Arbejdede med",
            "nb": "Jobbet med",
            "se": "Arbetade med",
            "pl": "Pracował z",
            "fr": "A travaillé avec",
            "nl": "Heeft gewerkt met",
            "de": "Hat gearbeitet mit"
        }
    }
]

export {  getRatedByForCountry, }